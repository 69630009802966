import { useRef, useState } from "react"
import pluralize from "pluralize"
import PropTypes from "prop-types"

import { getChangedQuestionResponseValues } from "../helpers/questionHelpers"
import * as customAssessmentResponseTypes from "../types"

import CompleteAssessmentSection from "./CompleteAssessmentSection"
import ResponseQuestion from "./ResponseQuestion"
import ResponseSection from "./ResponseSection"
import SubmitButton from "./SubmitButton"

import Form from "~/design/forms"
import api from "~/services/api"
import { toast } from "~/toast"
import types from "~/types"

function ResponseForm({
  questionResponseValues: initialQuestionResponseValues,
  response,
  schemaVersion,
  setResponse,
  carePlanSuggestionsFeatureEnabled,
}) {
  const [isCompleting, setIsCompleting] = useState(false)
  const prevQuestionResponseValues = useRef(initialQuestionResponseValues)

  async function handleSubmit({ questionResponseValues }, { setErrors }) {
    try {
      if (!isCompleting) {
        // On auto-save, only submit the values that have changed since the previous form submission
        // for performance reasons, so Rails doesn't have to check each nested question response attribute
        // when we call `question_responses_attributes=`
        const changedQuestionResponseValues = getChangedQuestionResponseValues(
          prevQuestionResponseValues.current,
          questionResponseValues
        )

        await api.customAssessments.responses.patch(response.id, {
          questionResponseValues: changedQuestionResponseValues,
        })

        prevQuestionResponseValues.current = questionResponseValues
      } else {
        // When completing the form, submit everything to ensure the assessment response is totally
        // up to date with the form
        const responseValues = await api.customAssessments.responseCompletes.patch(response.id, {
          questionResponseValues,
          markForCompletion: true,
        })

        setResponse(responseValues)
        if (carePlanSuggestionsFeatureEnabled && responseValues.careSuggestions.length > 0) {
          toast.success(
            `New care ${pluralize(
              "suggestion",
              responseValues.careSuggestions.length
            )} available on the active care plan.`
          )
        }
      }
      toast.success("Assessment saved successfully.")
    } catch ({ errors }) {
      setErrors(errors)
      setIsCompleting(false)

      const firstFieldWithError = document.querySelector("[aria-invalid=true]")
      if (firstFieldWithError) {
        firstFieldWithError.focus()
      }

      toast.error("Assessment could not be saved.")
    }
  }

  return (
    <Form vertical initialValues={{ questionResponseValues: initialQuestionResponseValues }} onSubmit={handleSubmit}>
      <Form.Autosave debounceMs={5000} />
      {schemaVersion.sections.map((section) => (
        <ResponseSection key={section.id} section={section}>
          {section.questions.map((question, index) => {
            const dependsOnQuestion = question.dependsOnQuestion
              ? section.questions.find(({ id }) => id === question.dependsOnQuestion.id)
              : null
            return (
              <ResponseQuestion
                key={question.id}
                question={question}
                questionNumber={index + 1}
                dependsOnQuestion={dependsOnQuestion}
              />
            )
          })}
        </ResponseSection>
      ))}
      <CompleteAssessmentSection>
        <div className="d-flex justify-content-between align-items-center border rounded p-3">
          <div>By clicking "Complete", the assessment form will be marked as done and finalized.</div>
          <SubmitButton isCompleting={isCompleting} setIsCompleting={setIsCompleting} />
        </div>
      </CompleteAssessmentSection>
    </Form>
  )
}

ResponseForm.propTypes = {
  response: types.customAssessmentResponse.isRequired,
  setResponse: PropTypes.func.isRequired,
  questionResponseValues: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.number)]).isRequired
  ),
  schemaVersion: customAssessmentResponseTypes.schemaVersion,
  carePlanSuggestionsFeatureEnabled: PropTypes.bool.isRequired,
}

export default ResponseForm
